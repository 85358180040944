<template>
  <div>
    <el-card>
      <common-user-center-title :title="'挑选商品'"></common-user-center-title>
      <div class="goods-page-content clearfix">
        <el-tabs class="f-l" tab-position="left" v-model="activeName" @tab-click="leftSwitchClick">
          <el-tab-pane :label="item.cname" :name="item.id+''" v-for="item in categoryList" :key="item.id"></el-tab-pane>
        </el-tabs>
        <div class="goods-box f-l">
          <div class="goods-list">
            <div class="goods-item" v-for="item in distributeGoodsList" :key="item.id">
              <div class="product-img">
                <img v-lazy="item.image.img"/>
              </div>
              <div class="product-info">
                <div class="product-name">{{item.title}}</div>
                <div class="price">
                  价格：<span>￥{{item.price}}</span>
                  佣金比例：<span>{{item.proportion}}%</span>
                </div>
              </div>
              <div class="options">
                <el-button v-if="item.isChoose" size="mini" type="success">已加入</el-button>
                <el-button v-else size="mini" type="primary" @click="addMyShop(item)">加入小店</el-button>
              </div>
            </div>
          </div>
          <!-- 分页区域 -->
          <el-pagination class="mt-2"
                         :current-page="distributeGoodsQueryInfo.page"
                         :page-sizes="[10, 20, 50, 100]"
                         :page-size="distributeGoodsQueryInfo.pageSize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="distributeGoodsTotal"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import { getCategory, getAllDistributeGoodsListUrl, addDistributeGoodsToMyShopUrl } from '@/api/index'

export default {
  name: 'ChoiceDistributionGoods',
  components: {
    commonUserCenterTitle
  },
  data () {
    return {
      categoryList: [],
      activeName: '',
      distributeGoodsList: [],
      distributeGoodsQueryInfo: {
        categoryId: '',
        pageSize: 10,
        page: 1,
        field: '',
        desc: '',
      },
      distributeGoodsTotal: 0,
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.getCategoryList()
    },
    // 获取所有分类
    async getCategoryList () {
      const { data: res } = await this.$http.get(getCategory)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.categoryList = res.data
      this.activeName = this.categoryList[0].id + ''
      this.getAllDistributeGoodsList(this.categoryList[0].id)
    },
    // 根据分类id获取对应的分销商品
    async getAllDistributeGoodsList (categoryId) {
      if (categoryId) {
        this.distributeGoodsQueryInfo.categoryId = categoryId
      }
      const { data: res } = await this.$http.get(getAllDistributeGoodsListUrl, { params: this.distributeGoodsQueryInfo })
      if (res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      this.distributeGoodsList = res.data.list
      this.distributeGoodsTotal = res.data.total
    },
    // 加入我的小店
    async addMyShop (item) {
      const { data: res } = await this.$http.post(addDistributeGoodsToMyShopUrl, { id: item.id })
      if (res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      item.isChoose = true
      this.$message.success('加入我的小店成功')
    },
    // 分页页码显示条数
    handleSizeChange (newSzie) {
      // 把每页显示多少数据重新赋值
      this.distributeGoodsQueryInfo.pageSize = newSzie
      // 重新获取数据并显示
      this.getAllDistributeGoodsList()
    },
    // 页面切换事件
    handleCurrentChange (newPage) {
      this.distributeGoodsQueryInfo.page = newPage
      // 重新获取数据并显示
      this.getAllDistributeGoodsList()
    },
    // 左侧菜单点击切换右侧商品
    leftSwitchClick () {
      this.distributeGoodsQueryInfo.page = 1
      this.getAllDistributeGoodsList(this.activeName)
    },
  }
}
</script>

<style lang="less" scoped>
  .goods-page-content {
    .el-tabs {
    }

    .goods-box {
      width: 760px;
      margin-left: 10px;

      .goods-item {
        position: relative;
        display: flex;
        justify-content: start;
        padding-bottom: 8px;
        border-bottom: 1px solid #dddddd;
        margin-top: 14px;

        &:first-child {
          margin-top: 0;
        }

        .product-img {
          margin-right: 20px;

          img {
            width: 66px;
            height: 66px;
          }
        }

        .product-info {
          height: 74px;

          .product-name {
            font-size: 14px;
            color: #333333;
            width: 100%;
            height: 42px;
            white-space: inherit;
            text-overflow: inherit;
            overflow: hidden;
          }

          .price {
            font-size: 14px;
            color: #666666;

            span {
              color: #EF001C;
              margin-right: 20px;
            }
          }
        }

        .options {
          position: absolute;
          bottom: 20px;
          right: 0;
        }
      }
    }
  }
</style>
